// Generated by Framer (d9af232)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {t3METiPBw: {hover: true}};

const cycleOrder = ["t3METiPBw"];

const serializationHash = "framer-jaXDb"

const variantClassNames = {t3METiPBw: "framer-v-snwhqh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const radiusForCorner = (value, cornerIndex) => {
    if (typeof value === "number" && Number.isFinite(value)) return Math.max(0, value) + "px";
    if (typeof value !== "string" || typeof cornerIndex !== "number") return undefined;
    const segments = value.split(" ");
    return segments[cornerIndex] || segments[cornerIndex - 2] || segments[0];
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({background, border, height, hoverBackground, icon, id, link, radius, width, ...props}) => { return {...props, b9M7GOmdn: radius ?? props.b9M7GOmdn ?? "12px", dSXguc6mZ: icon ?? props.dSXguc6mZ ?? {src: "https://framerusercontent.com/images/Sd0DZPLdEsFdm3lw3it5X8lCnM.svg"}, MdoskHwe5: background ?? props.MdoskHwe5 ?? "var(--token-64302b65-26bc-4e69-ae4d-c9415e9b76b9, rgb(0, 4, 31))", ovOZfCcN8: border ?? props.ovOZfCcN8 ?? {borderColor: "var(--token-dcbf9366-25e8-40cb-a413-0c58fea4a46d, rgb(14, 18, 46)) /* {\"name\":\"Grey 12\"} */", borderStyle: "solid", borderWidth: 1}, R7WNrXe7y: hoverBackground ?? props.R7WNrXe7y ?? "var(--token-7c1fcccd-1968-4fc4-8760-c2253b862494, rgb(0, 6, 51))", XAlGRr1kt: link ?? props.XAlGRr1kt} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;icon?: {src: string; srcSet?: string};background?: string;hoverBackground?: string;border?: Record<string, any>;radius?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XAlGRr1kt, dSXguc6mZ, MdoskHwe5, R7WNrXe7y, ovOZfCcN8, b9M7GOmdn, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "t3METiPBw", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={XAlGRr1kt}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-snwhqh", className, classNames)} framer-vlblsh`} data-border data-framer-name={"Icon Button"} layoutDependency={layoutDependency} layoutId={"t3METiPBw"} ref={ref ?? ref1} style={{"--border-bottom-width": `${ovOZfCcN8.borderBottomWidth ?? ovOZfCcN8.borderWidth}px`, "--border-color": ovOZfCcN8.borderColor, "--border-left-width": `${ovOZfCcN8.borderLeftWidth ?? ovOZfCcN8.borderWidth}px`, "--border-right-width": `${ovOZfCcN8.borderRightWidth ?? ovOZfCcN8.borderWidth}px`, "--border-style": ovOZfCcN8.borderStyle, "--border-top-width": `${ovOZfCcN8.borderTopWidth ?? ovOZfCcN8.borderWidth}px`, backgroundColor: MdoskHwe5, borderBottomLeftRadius: radiusForCorner(b9M7GOmdn, 3), borderBottomRightRadius: radiusForCorner(b9M7GOmdn, 2), borderTopLeftRadius: radiusForCorner(b9M7GOmdn, 0), borderTopRightRadius: radiusForCorner(b9M7GOmdn, 1), ...style}} variants={{"t3METiPBw-hover": {backgroundColor: R7WNrXe7y}}} {...addPropertyOverrides({"t3METiPBw-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", pixelHeight: 18, pixelWidth: 18, sizes: "18px", ...toResponsiveImage(dSXguc6mZ)}} className={"framer-egjfss"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"jDuIHDrkC"}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jaXDb.framer-vlblsh, .framer-jaXDb .framer-vlblsh { display: block; }", ".framer-jaXDb.framer-snwhqh { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 12px; position: relative; text-decoration: none; width: min-content; }", ".framer-jaXDb .framer-egjfss { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 18px); overflow: hidden; position: relative; width: 18px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jaXDb.framer-snwhqh { gap: 0px; } .framer-jaXDb.framer-snwhqh > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-jaXDb.framer-snwhqh > :first-child { margin-left: 0px; } .framer-jaXDb.framer-snwhqh > :last-child { margin-right: 0px; } }", ".framer-jaXDb[data-border=\"true\"]::after, .framer-jaXDb [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 42
 * @framerIntrinsicWidth 42
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Nrjlmixai":{"layout":["auto","auto"]}}}
 * @framerVariables {"XAlGRr1kt":"link","dSXguc6mZ":"icon","MdoskHwe5":"background","R7WNrXe7y":"hoverBackground","ovOZfCcN8":"border","b9M7GOmdn":"radius"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramercJMQ7SmZx: React.ComponentType<Props> = withCSS(Component, css, "framer-jaXDb") as typeof Component;
export default FramercJMQ7SmZx;

FramercJMQ7SmZx.displayName = "Buttons/ Icon Button";

FramercJMQ7SmZx.defaultProps = {height: 42, width: 42};

addPropertyControls(FramercJMQ7SmZx, {XAlGRr1kt: {title: "Link", type: ControlType.Link}, dSXguc6mZ: {__defaultAssetReference: "data:framer/asset-reference,Sd0DZPLdEsFdm3lw3it5X8lCnM.svg?originalFilename=mail.svg&preferredSize=auto", title: "Icon", type: ControlType.ResponsiveImage}, MdoskHwe5: {defaultValue: "var(--token-64302b65-26bc-4e69-ae4d-c9415e9b76b9, rgb(0, 4, 31)) /* {\"name\":\"Dark 06\"} */", title: "Background", type: ControlType.Color}, R7WNrXe7y: {defaultValue: "var(--token-7c1fcccd-1968-4fc4-8760-c2253b862494, rgb(0, 6, 51)) /* {\"name\":\"Dark 10\"} */", title: "Hover Background", type: ControlType.Color}, ovOZfCcN8: {defaultValue: {borderColor: "var(--token-dcbf9366-25e8-40cb-a413-0c58fea4a46d, rgb(14, 18, 46)) /* {\"name\":\"Grey 12\"} */", borderStyle: "solid", borderWidth: 1}, title: "Border", type: ControlType.Border}, b9M7GOmdn: {defaultValue: "12px", title: "Radius", type: ControlType.BorderRadius}} as any)

addFonts(FramercJMQ7SmZx, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})